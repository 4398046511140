import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import {LayoutComponent} from '@amzn/nazare-websites-shared-assets';
import FileUploadComponent from 'src/components/FileUpload/FileUploadComponent';
import Toaster from '@amzn/meridian/toaster';
import Alert from '@amzn/meridian/alert';
import {uploadS3Object} from '../FileService';
import APP_CONFIG from 'src/common/auth/appConfig';

interface UploadMacState {
  toasts: any[];
  buttonDisabled: Boolean;
  fileName: String;
  fileUploaded: Boolean;
}

interface UploadMacProps {
  businessUnitValue: String;
  regionValue: String;
  projectPhaseValue: String;
}

export class UploadMac extends Component<RouteComponentProps, UploadMacState> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      toasts: [],
      buttonDisabled: false,
      fileName: '',
      fileUploaded: false,
    };
  }

  onFileAttached = async (acceptedFiles: any) => {
    this.onRemoveUploadedFile();
    acceptedFiles.forEach(async (file: any) => {
      this.setState({
        fileName: file.name,
        toasts: [],
      });

      const payload = {
        property: {
          file: file,
          bucketName: APP_CONFIG.S3_BUCKET_UPLOAD,
          metadata: {
            business: (this.props.location.state as UploadMacProps)
              .businessUnitValue as string,
            region: (this.props.location.state as UploadMacProps)
              .regionValue as string,
          },
        },
        config: {
          overwriteWhenDuplicate: true,
        },
      };

      try {
        const res = await uploadS3Object(payload);
        if (res) {
          this.setState({
            toasts: [{type: 'success', text: 'Material Catalog Uploaded'}],
            fileUploaded: true,
          });
        }
      } catch (err) {
        this.setState({
          toasts: [{type: 'error', text: 'Upload Failed! ' + err}],
        });
      }
    });
  };

  onCancelFileUpload = () => {
    this.setState({
      fileUploaded: false,
      toasts: [],
    });
  };

  onRemoveUploadedFile = () => {
    this.setState({
      fileName: '',
      fileUploaded: false,
      toasts: [],
    });
  };

  render() {
    return (
      <React.Fragment>
        <LayoutComponent
          onClickSidebar={() => {}}
          onClickHeader={() => {}}
          sidebarItems={[]}
        >
          <div>
            <Toaster
              toasts={this.state.toasts}
              onCloseToast={() => this.setState({toasts: []})}
            >
              {toast => (
                <Alert toast={true} onClose={toast.onClose}>
                  {toast.text}
                </Alert>
              )}
            </Toaster>
            <Heading level={1} data-testid="heading">
              Upload Material Catalog
            </Heading>
            <Text>
              Business Unit:{' '}
              {(this.props.location.state as UploadMacProps).businessUnitValue}
            </Text>
            <Text>
              Region:{' '}
              {(this.props.location.state as UploadMacProps).regionValue}
            </Text>
            <Text>
              Project Phase:{' '}
              {(this.props.location.state as UploadMacProps).projectPhaseValue}
            </Text>
            <FileUploadComponent
              onFileAttached={this.onFileAttached}
              uploadButtonLabel={'Upload Material Catalog'}
              isFileUploadCompleted={this.state.fileUploaded}
              onCancelFileUpload={this.onCancelFileUpload}
              onRemoveUploadedFile={this.onRemoveUploadedFile}
            >
              {}
            </FileUploadComponent>
          </div>
        </LayoutComponent>
      </React.Fragment>
    );
  }
}

export default UploadMac;
