import React, {Component} from 'react';
import Text from '@amzn/meridian/text';
import Link from '@amzn/meridian/link';

export const manageMacExampleData = {
  headers: [
    {
      value: 'Date',
      label: 'date',
    },
    {
      value: 'File Details',
      label: 'file_details',
    },
    {
      value: 'Status',
      label: 'status',
    },
    {
      value: 'Rows',
      label: 'rows',
    },
    {
      value: 'Link',
      label: 'link',
    },
  ],
  rowData: [
    {
      date: '5/16',
      file_details: 'Catalog',
      status: 'Success',
      rows: 5000,
      link: <Link>Download Catalog</Link>,
    },
    {
      date: '6/7',
      file_details: 'CC = "CC1", WD = "WD1"',
      status: <Text color="error">Failure</Text>,
      link: <Link>Download Error</Link>,
    },
    {
      date: '6/8',
      status: 'In-progress',
    },
    {
      date: '6/9',
      status: 'Queued',
    },
  ],
  numberOfPages: 1,
  onToggleSelect: () => {},
};
