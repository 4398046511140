import {uploadSingleObject, uploadMultipartObject} from './strategies';

const FILE_UPLOAD_STRATEGY_SIZE_THRESHOLD = 25000000000; // 100MB

export interface UploadS3ObjectPayload {
  property: {
    file: File;
    fileKey?: string;
    bucketName: string;
    metadata?: {[key: string]: string};
  };
  config: {
    apiVersion?: number;
    overwriteWhenDuplicate?: boolean;
  };
}

export const uploadS3Object = async (payload: UploadS3ObjectPayload) => {
  if (payload.property.file.size > FILE_UPLOAD_STRATEGY_SIZE_THRESHOLD) {
    return uploadMultipartObject(payload);
  } else {
    return uploadSingleObject(payload);
  }
};
