import {getEnv} from '../constants';

export interface APIConfig {
  macApiUrl: string;
  assumedRoleArn: string;
  clientId: string;
  S3_BUCKET_UPLOAD: string;
  region: string;
}

const BETA_APP_CONFIG: APIConfig = {
  macApiUrl: 'https://e5lld2ngzc.execute-api.us-east-2.amazonaws.com',
  assumedRoleArn: 'arn:aws:iam::353242601996:role/AssumeRoleWithWebIdentity',
  clientId: 'https://beta.mac-ui.nazir.amazon.dev',
  S3_BUCKET_UPLOAD: 'mac-request-353242601996-us-east-2-bucket',
  region: 'us-east-1',
};

const GAMMA_APP_CONFIG: APIConfig = {
  macApiUrl: 'https://7k4mgkr5ph.execute-api.us-east-2.amazonaws.com',
  assumedRoleArn: 'arn:aws:iam::347166121661:role/AssumeRoleWithWebIdentity',
  clientId: 'https://gamma.mac-ui.nazir.amazon.dev',
  S3_BUCKET_UPLOAD: 'mac-request-347166121661-us-east-2-bucket',
  region: 'us-east-1',
};

const UAT_APP_CONFIG: APIConfig = {
  macApiUrl: '', // TODO add api url
  assumedRoleArn: 'arn:aws:iam::509788820167:role/AssumeRoleWithWebIdentity',
  clientId: 'https://uat.mac-ui.nazir.amazon.dev',
  S3_BUCKET_UPLOAD: 'mac-request-509788820167-us-east-2-bucket',
  region: 'us-east-1',
};

const PROD_NA_APP_CONFIG: APIConfig = {
  macApiUrl: '', // TODO add api url
  assumedRoleArn: 'arn:aws:iam::244893960250:role/AssumeRoleWithWebIdentity',
  clientId: 'https://prod-na.mac-ui.nazir.amazon.dev',
  S3_BUCKET_UPLOAD: 'mac-request-244893960250-us-east-2-bucket',
  region: 'us-east-1',
};

const PROD_EU_APP_CONFIG: APIConfig = {
  macApiUrl: '', // TODO add api url
  assumedRoleArn: '', // TODO add url
  clientId: 'https://prod-eu.mac-ui.nazir.amazon.dev',
  S3_BUCKET_UPLOAD: '', // TODO add url
  region: 'us-east-1',
};

const env = getEnv();
let APP_CONFIG: APIConfig = BETA_APP_CONFIG;
if (env === 'beta') {
  APP_CONFIG = BETA_APP_CONFIG;
} else if (env === 'gamma') {
  APP_CONFIG = GAMMA_APP_CONFIG;
} else if (env === 'uat') {
  APP_CONFIG = UAT_APP_CONFIG;
} else if (env === 'prod-na') {
  APP_CONFIG = PROD_NA_APP_CONFIG;
} else if (env === 'prod-eu') {
  APP_CONFIG = PROD_EU_APP_CONFIG;
}

export async function initAppConfig() {}

export default APP_CONFIG;
