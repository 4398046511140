import {UploadS3ObjectPayload} from '../UploadS3Object';
import {fileServiceEndpoints} from '../constants/FileServiceEndpoints';
import {objectCannedACL} from '../constants/S3Constants';
import {executeRequest} from 'src/common/auth/signRequest';
import axios from 'axios';

interface MultipartUploadResponse {
  presignedUrlList: string[];
  partSizeInByte: number;
  uploadId: string;
}

interface PartDetails {
  eTag: string;
  partNumber: number;
}

export const uploadFile = async (payload: UploadS3ObjectPayload) => {
  const s3Response = await createMultipartUpload(payload);
  const partDetailsList = await startS3MultipartUpload(payload, s3Response);
  return await completeS3MultipartUpload(payload, s3Response, partDetailsList);
};

const createMultipartUpload = async (
  payload: UploadS3ObjectPayload
): Promise<MultipartUploadResponse> => {
  const {property, config} = payload;

  // Construct headers
  let headers: {[key: string]: string} = {};

  // Construct body
  const metadata = property.metadata
    ? JSON.stringify(property.metadata)
    : undefined;

  const fileKey = property.fileKey ? property.fileKey : property.file.name;

  const body = {
    fileProperty: {
      type: property.file.type,
      size: property.file.size,
      metadata: metadata,
    },
    s3Details: {
      fileKey: fileKey,
      bucketName: property.bucketName,
      overwriteWhenDuplicate: config.overwriteWhenDuplicate,
    },
  };

  // Create request
  const request = {
    path: fileServiceEndpoints.UPLOAD_MULTIPART_START.path,
    method: fileServiceEndpoints.UPLOAD_MULTIPART_START.method,
    data: body,
  };

  // Call API
  const res = await executeRequest(request);

  if (!res) {
    throw new Error('Multipart Upload Start API returns empty reponse');
  }

  return res.data as MultipartUploadResponse;
};

const startS3MultipartUpload = async (
  payload: UploadS3ObjectPayload,
  s3Response: MultipartUploadResponse
): Promise<PartDetails[]> => {
  const {property} = payload;

  const {presignedUrlList: urls, partSizeInByte: partSize} = s3Response;

  return await Promise.all(
    urls.map(async (url, idx) => {
      // Construct headers
      let headers: {[key: string]: string} = {
        'Content-Type': property.file.type,
        'x-amz-acl': objectCannedACL.BUCKET_OWNER_FULL_CONTROL,
      };

      // Construct body
      const start = idx * partSize;
      const end = (idx + 1) * partSize;
      const fileBlob =
        idx === urls.length - 1
          ? property.file.slice(start, end)
          : property.file.slice(start);

      // Put file chunk to s3 bucket
      const res = await axios.put(url, fileBlob, {headers});

      const partDetails: PartDetails = {
        eTag: res.headers['etag'],
        partNumber: idx,
      };

      return partDetails;
    })
  );
};

const completeS3MultipartUpload = async (
  payload: UploadS3ObjectPayload,
  s3Response: MultipartUploadResponse,
  partDetailsList: PartDetails[]
) => {
  const {property, config} = payload;

  // Construct body
  const body = {
    fileKey: property.fileKey ? property.fileKey : property.file.name,
    bucketName: property.bucketName,
    uploadId: s3Response.uploadId,
    parts: partDetailsList,
  };

  const opts = {
    method: 'POST',
    body: JSON.stringify(body),
  };

  // Create request
  const request = {
    path: fileServiceEndpoints.UPLOAD_MULTIPART_COMPLETE.path,
    method: fileServiceEndpoints.UPLOAD_MULTIPART_COMPLETE.method,
    data: body,
  };

  // Call API
  const res = await executeRequest(request);

  if (!res) {
    throw new Error('Multipart Upload Complete API returns empty reponse');
  }

  return res.data;
};
