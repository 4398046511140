import React from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import {MaterialCatalog} from 'src/components/MaterialCatalog/MaterialCatalog';
import {paths} from 'src/common/constants';
import history from 'src/common/history';
import CreateMac from './components/CreateMac/CreateMac';
import UploadMac from './components/UploadMac/UploadMac';
import ManageMac from './components/ManageMac/ManageMac';

function AppRouter() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path={paths.materialCatalog} component={MaterialCatalog} />
        <Route exact path={paths.createMac} component={CreateMac} />
        <Route exact path={paths.uploadMac} component={UploadMac} />
        <Route exact path={paths.manageMac} component={ManageMac} />
      </Switch>
    </Router>
  );
}

export default AppRouter;
