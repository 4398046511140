import React, {Component} from 'react';
import {
  LayoutComponent,
  ButtonComponent,
  TableComponent,
} from '@amzn/nazare-websites-shared-assets';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import {RouteComponentProps} from 'react-router';
import history from 'src/common/history';
import {manageMacExampleData} from 'src/common/manageMacExampleData';
import {paths} from 'src/common/constants';

interface ManageMacProps {
  businessUnitValue: String;
  regionValue: String;
  projectPhaseValue: String;
}

interface ManageMacState {
  businessUnitValue: String;
  regionValue: String;
  projectPhaseValue: String;
}

export class ManageMac extends Component<RouteComponentProps, ManageMacState> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      businessUnitValue: (this.props.location.state as ManageMacProps)
        .businessUnitValue,
      regionValue: (this.props.location.state as ManageMacProps).regionValue,
      projectPhaseValue: (this.props.location.state as ManageMacProps)
        .projectPhaseValue,
    };
  }

  onClickUpload = () => {
    history.push({
      pathname: paths.uploadMac,
      state: {
        businessUnitValue: this.state.businessUnitValue,
        regionValue: this.state.regionValue,
        projectPhaseValue: this.state.projectPhaseValue,
      },
    });
  };

  render() {
    return (
      <React.Fragment>
        <LayoutComponent
          children={
            <Column spacingInset="450" width="100%">
              <Row>
                <Heading level={1}>Manage Material Catalog</Heading>
              </Row>
              <Row>
                <Column>
                  <Text>
                    Business:{' '}
                    {
                      (this.props.location.state as ManageMacProps)
                        .businessUnitValue
                    }
                  </Text>
                </Column>
                <Column>
                  <Text>
                    Region:{' '}
                    {(this.props.location.state as ManageMacProps).regionValue}
                  </Text>
                </Column>
                <Column>
                  <Text>
                    Project Phase:{' '}
                    {
                      (this.props.location.state as ManageMacProps)
                        .projectPhaseValue
                    }
                  </Text>
                </Column>
              </Row>
              <Row>
                <ButtonComponent>Download Template</ButtonComponent>
                <ButtonComponent>Download MaC</ButtonComponent>
                <ButtonComponent
                  onClickButton={this.onClickUpload}
                  data-testid="uploadButton"
                >
                  Upload MaC
                </ButtonComponent>
                <ButtonComponent>Search MaC</ButtonComponent>
              </Row>
              <Row>
                <TableComponent
                  data={manageMacExampleData}
                  onToggleSelect={() => {}}
                ></TableComponent>
              </Row>
            </Column>
          }
          onClickSidebar={() => {}}
          onClickHeader={() => {}}
          sidebarItems={[]}
        ></LayoutComponent>
      </React.Fragment>
    );
  }
}

export default ManageMac;
