export const paths = {
  materialCatalog: '/material-catalog',
  manageMac: '/material-catalog/*/manage-mac',
  createMac: '/material-catalog/create-mac',
  uploadMac: '/material-catalog/*/upload-mac',
};

export const businessConstants = {
  businessUnits: ['GCF'],
  regions: ['NA', 'EU'],
  projectPhases: ['DD', 'RE', 'CONSTRUCTION'],
};

export enum MethodTypes {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

export const getEnv = () => {
  if (window.location.href.includes('https://beta.mac-ui.nazir.amazon.dev')) {
    return 'beta';
  } else if (
    window.location.href.includes('https://gamma.mac-ui.nazir.amazon.dev')
  ) {
    return 'gamma';
  } else if (
    window.location.href.includes('https://uat.mac-ui.nazir.amazon.dev')
  ) {
    return 'uat';
  } else if (
    window.location.href.includes('https://prod-na.mac-ui.nazir.amazon.dev')
  ) {
    return 'prod-na';
  } else if (
    window.location.href.includes('https://prod-eu.mac-ui.nazir.amazon.dev')
  ) {
    return 'prod-eu';
  } else {
    return 'dev';
  }
};
