import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import Toaster from '@amzn/meridian/toaster';
import Alert from '@amzn/meridian/alert';
import {
  ButtonComponent,
  SelectComponent,
  LayoutComponent,
} from '@amzn/nazare-websites-shared-assets';
import macModelApi from 'src/common/macModel';

interface CreateMacState {
  hierarchyLevel: String;
  toasts: any[];
  buttonDisabled: Boolean;
}

interface CreateMacProps {
  businessUnitValue: String;
  regionValue: String;
  projectPhaseValue: String;
}

export class CreateMac extends Component<RouteComponentProps, CreateMacState> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      hierarchyLevel: '',
      toasts: [],
      buttonDisabled: false,
    };
    this.onChangeHierarchy = this.onChangeHierarchy.bind(this);
  }

  onChangeHierarchy = (value: String) => {
    this.setState({hierarchyLevel: value});
  };

  onClickSubmit = () => {
    if (!this.state.hierarchyLevel) {
      return;
    }
    this.setState({toasts: []});
    const requestData = {
      config: {
        business: (this.props.location.state as CreateMacProps)
          .businessUnitValue,
        region: (this.props.location.state as CreateMacProps).regionValue,
        projectPhase: (this.props.location.state as CreateMacProps)
          .projectPhaseValue,
      },
      depth: this.state.hierarchyLevel,
    };
    macModelApi
      .createCatalogConfig(requestData)
      .then(data => {
        if (!data) {
          this.setState({
            toasts: [
              {
                type: 'error',
                text: 'Catalog cannot be created because it already exists!',
              },
            ],
          });
        } else {
          this.setState({
            toasts: [
              {
                type: 'success',
                text: 'Catalog config created successfully!',
              },
            ],
          });
        }
      })
      .catch((err: any) => {
        this.setState({
          toasts: [{type: 'error', text: 'Catalog config creation failed!'}],
        });
      });
    this.setState({buttonDisabled: true});
  };

  render() {
    return (
      <React.Fragment>
        <LayoutComponent
          onClickSidebar={() => {}}
          onClickHeader={() => {}}
          sidebarItems={[]}
        >
          <div>
            <Toaster
              toasts={this.state.toasts}
              onCloseToast={() => this.setState({toasts: []})}
            >
              {toast => (
                <Alert toast={true} onClose={toast.onClose}>
                  {toast.text}
                </Alert>
              )}
            </Toaster>
            <Heading level={1}>Create Material Catalog</Heading>
            <Text>
              Business Unit:{' '}
              {(this.props.location.state as CreateMacProps).businessUnitValue}
            </Text>
            <Text>
              Region:{' '}
              {(this.props.location.state as CreateMacProps).regionValue}
            </Text>
            <Text>
              Project Phase:{' '}
              {(this.props.location.state as CreateMacProps).projectPhaseValue}
            </Text>
            <SelectComponent
              items={[2, 3, 4, 5, 6, 7, 8, 9, 10]}
              placeholder={'Hierarchy Level'}
              onChangeItem={this.onChangeHierarchy}
              value={this.state.hierarchyLevel}
              width={200}
            ></SelectComponent>
            <ButtonComponent
              onClickButton={() => this.onClickSubmit()}
              data-testid="submitButton"
              disabled={this.state.buttonDisabled}
            >
              Submit
            </ButtonComponent>
          </div>
        </LayoutComponent>
      </React.Fragment>
    );
  }
}

export default CreateMac;
