import {executeRequest} from './auth/signRequest';
import {MethodTypes} from 'src/common/constants';
import {getEnv} from 'src/common/constants';

const envName = getEnv();

interface APIRequest {
  path: string;
  method: MethodTypes;
  data?: any; // request body
}

const API_URLS = {
  getCatalog: () => `/${envName}/v1/catalog/get`,
  createCatalogConfig: () => `/${envName}/v1/config/create`,
  getMaterialDetails: () => `/${envName}/v1/material/details`,
  getMaterialId: () => `/${envName}/v1/material/id`,
  getPresignedS3UrlForSingleFileUpload: () =>
    `/${envName}/v1/file/upload/single`,
};

export const getCatalog = async (payload: any) => {
  const request: APIRequest = {
    path: API_URLS.getCatalog(),
    method: MethodTypes.POST,
    data: payload,
  };
  const response = await executeRequest(request);
  return response?.data;
};

export const createCatalogConfig = async (payload: any) => {
  const request: APIRequest = {
    path: API_URLS.createCatalogConfig(),
    method: MethodTypes.POST,
    data: payload,
  };
  const response = await executeRequest(request);
  return response?.data;
};

export const getMaterialDetails = async (payload: any) => {
  const request: APIRequest = {
    path: API_URLS.getMaterialDetails(),
    method: MethodTypes.POST,
    data: payload,
  };
  const response = await executeRequest(request);
  return response?.data;
};

export const getMaterialId = async (payload: any) => {
  const request: APIRequest = {
    path: API_URLS.getMaterialId(),
    method: MethodTypes.POST,
    data: payload,
  };
  const response = await executeRequest(request);
  return response?.data;
};

export const getPresignedS3UrlForSingleFileUpload = async (payload: any) => {
  const request: APIRequest = {
    path: API_URLS.getPresignedS3UrlForSingleFileUpload(),
    method: MethodTypes.POST,
    data: payload,
  };
  const response = await executeRequest(request);
  return response?.data;
};

export default {
  getCatalog,
  createCatalogConfig,
  getMaterialDetails,
  getMaterialId,
  getPresignedS3UrlForSingleFileUpload,
};
