// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, {useState} from 'react';
import FileInput, {FileDetails} from '@amzn/meridian/file-input';

const FileUploadComponent = ({
  onFileAttached,
  isFileUploadCompleted,
  onCancelFileUpload,
  onRemoveUploadedFile,
  ...args
}) => {
  const [files, setFiles] = useState([]);

  const onFileUpload = (updatedFileArr: any) => {
    setFiles(updatedFileArr);
    onFileAttached(updatedFileArr);
  };

  return (
    <FileInput
      onFileAttached={acceptedFiles => {
        onFileAttached(acceptedFiles);
        setFiles(acceptedFiles);
      }}
      accept=".xlsx"
      {...args}
    >
      {files.map(file => {
        return (
          <FileDetails
            error={file.error}
            errorMessage={file.errorMessage}
            file={file}
            key={file.name}
            cancelUploadButtonLabel={`Cancel, ${file.name}`}
            onClickCancel={() => {
              onCancelFileUpload();
              const updatedFileArr = files.filter(fl => fl.name !== file.name);
              onFileUpload(updatedFileArr);
            }}
            removeFileButtonLabel={`Delete, ${file.name}`}
            onClickRemoveFile={() => {
              onRemoveUploadedFile();
              const updatedFileArr = files.filter(fl => fl.name !== file.name);
              onFileUpload(updatedFileArr);
            }}
            uploadComplete={isFileUploadCompleted}
          />
        );
      })}
    </FileInput>
  );
};

FileUploadComponent.defaultProps = {
  uploadButtonDisabled: false,
  uploadButtonSize: 'medium',
  uploadButtonType: 'primary',
  type: 'single',
};

export default FileUploadComponent;
