import {MethodTypes} from 'src/common/constants';
import {getEnv} from 'src/common/constants';

const envName = getEnv();

export const fileServiceEndpoints: {
  [key: string]: {method: MethodTypes; path: string};
} = {
  UPLOAD_SINGLE: {
    method: MethodTypes.POST,
    path: `/${envName}/v1/file/upload/single`,
  },
  UPLOAD_MULTIPART_START: {
    method: MethodTypes.POST,
    path: `/${envName}/v1/file/upload/multipart/start`,
  },
  UPLOAD_MULTIPART_COMPLETE: {
    method: MethodTypes.POST,
    path: `/${envName}/v1/file/upload/multipart/complete`,
  },
  DOWNLOAD: {
    method: MethodTypes.POST,
    path: `/${envName}/v1/file/download`,
  },
};
