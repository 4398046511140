import {UploadS3ObjectPayload} from '../UploadS3Object';
import {fileServiceEndpoints} from '../constants/FileServiceEndpoints';
import {objectCannedACL} from '../constants/S3Constants';
import {executeRequest} from 'src/common/auth/signRequest';
import axios from 'axios';

interface S3SingleObjectUploadResponse {
  presignedUrl: string;
}

export const uploadFile = async (payload: UploadS3ObjectPayload) => {
  const s3Response = await generateS3PresignedUrl(payload);
  return await uploadS3ObjectViaPresignedUrl(payload, s3Response);
};

const generateS3PresignedUrl = async (
  payload: UploadS3ObjectPayload
): Promise<S3SingleObjectUploadResponse> => {
  const {property, config} = payload;

  // Construct body
  const metadata = property.metadata
    ? JSON.stringify(property.metadata)
    : undefined;

  const fileKey = property.fileKey ? property.fileKey : property.file.name;

  const body = {
    fileProperty: {
      type: property.file.type,
      size: property.file.size,
      metadata: metadata,
    },
    s3Details: {
      fileKey: fileKey,
      bucketName: property.bucketName,
      overwriteWhenDuplicate: config.overwriteWhenDuplicate,
    },
  };

  // Create request
  const request = {
    path: fileServiceEndpoints.UPLOAD_SINGLE.path,
    method: fileServiceEndpoints.UPLOAD_SINGLE.method,
    data: body,
  };

  // Call API
  const res = await executeRequest(request);

  if (!res) {
    throw new Error('Single Object Upload API returns empty reponse');
  }

  return res.data as S3SingleObjectUploadResponse;
};

const uploadS3ObjectViaPresignedUrl = async (
  payload: UploadS3ObjectPayload,
  s3Response: S3SingleObjectUploadResponse
) => {
  const {property} = payload;

  // Construct headers
  let headers: {[key: string]: string} = {
    'Content-Type': property.file.type,
    'x-amz-acl': objectCannedACL.BUCKET_OWNER_FULL_CONTROL,
  };

  if (property.metadata) {
    Object.entries(property.metadata).forEach(
      ([key, value]) => (headers = {...headers, [`x-amz-meta-${key}`]: value})
    );
  }

  return await axios.put(s3Response.presignedUrl, property.file, {
    headers,
  });
};
