import React, {Component} from 'react';
import history from 'src/common/history';
import {paths, businessConstants} from 'src/common/constants';
import Heading from '@amzn/meridian/heading';
import {
  ButtonComponent,
  SelectComponent,
  LayoutComponent,
} from '@amzn/nazare-websites-shared-assets';

interface MaterialCatalogState {
  businessUnitValue: String;
  regionValue: String;
  projectPhaseValue: String;
}

export class MaterialCatalog extends Component<{}, MaterialCatalogState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      businessUnitValue: '',
      regionValue: '',
      projectPhaseValue: '',
    };
  }

  onChangeBusinessUnit = (value: String) => {
    this.setState({businessUnitValue: value});
  };

  onChangeRegion = (value: String) => {
    this.setState({regionValue: value});
  };

  onChangeprojectPhase = (value: String) => {
    this.setState({projectPhaseValue: value});
  };

  onClickCreateCatalog = () => {
    if (
      this.state.businessUnitValue &&
      this.state.regionValue &&
      this.state.projectPhaseValue
    ) {
      history.push({
        pathname: paths.createMac,
        state: {
          businessUnitValue: this.state.businessUnitValue,
          regionValue: this.state.regionValue,
          projectPhaseValue: this.state.projectPhaseValue,
        },
      });
    }
  };

  onClickManageCatalog = () => {
    if (
      this.state.businessUnitValue &&
      this.state.regionValue &&
      this.state.projectPhaseValue
    ) {
      history.push({
        pathname: paths.manageMac,
        state: {
          businessUnitValue: this.state.businessUnitValue,
          regionValue: this.state.regionValue,
          projectPhaseValue: this.state.projectPhaseValue,
        },
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <LayoutComponent
          onClickSidebar={() => {}}
          onClickHeader={() => {}}
          sidebarItems={[]}
        >
          <div>
            <Heading level={1}>Material Catalog</Heading>
            <SelectComponent
              items={businessConstants.businessUnits}
              placeholder={'Business Unit'}
              onChangeItem={this.onChangeBusinessUnit}
              value={this.state.businessUnitValue}
              width={200}
              data-testid="businessUnitSelect"
            ></SelectComponent>
            <SelectComponent
              items={businessConstants.regions}
              placeholder={'Region'}
              onChangeItem={this.onChangeRegion}
              value={this.state.regionValue}
              width={200}
              data-testid="regionSelect"
            ></SelectComponent>
            <SelectComponent
              items={businessConstants.projectPhases}
              placeholder={'Project Phase'}
              onChangeItem={this.onChangeprojectPhase}
              value={this.state.projectPhaseValue}
              width={200}
              data-testid="projectPhaseSelect"
            ></SelectComponent>
            <ButtonComponent
              onClickButton={this.onClickCreateCatalog}
              data-testid="createCatalogButton"
            >
              {'Create Catalog'}
            </ButtonComponent>
            <ButtonComponent
              onClickButton={this.onClickManageCatalog}
              data-testid="manageCatalogButton"
            >
              {'Manage Catalog'}
            </ButtonComponent>
          </div>
        </LayoutComponent>
      </React.Fragment>
    );
  }
}

export default MaterialCatalog;
