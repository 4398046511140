import moment from 'moment';
import {
  STSClient,
  AssumeRoleWithWebIdentityCommand,
  AssumeRoleWithWebIdentityCommandInput,
  Credentials,
} from '@aws-sdk/client-sts';
import {getWebIdentityTokenOrRedirect, getUserAlias} from './midwayAuth';
import APP_CONFIG from './appConfig';

let stsClient: STSClient;

let credentials: Credentials | undefined;

async function generateCredentials(
  roleArn: string,
  roleSessionName: string,
  webIdentityToken: string
): Promise<Credentials | undefined> {
  const params: AssumeRoleWithWebIdentityCommandInput = {
    RoleArn: roleArn,
    RoleSessionName: roleSessionName,
    WebIdentityToken: webIdentityToken,
  };
  const command = new AssumeRoleWithWebIdentityCommand(params);
  try {
    const response = await stsClient.send(command);
    return response.Credentials;
  } catch (error) {
    console.error(`Failed to fetch AWS credentials. Error: ${error}`);
    return undefined;
  }
}

async function refreshCredentials(
  roleArn: string,
  roleSessionName: string,
  webIdentityToken: string
) {
  credentials = await generateCredentials(
    roleArn,
    roleSessionName,
    webIdentityToken
  );

  const expireTime = moment(credentials?.Expiration);
  if (expireTime) {
    const curTime = moment();
    const timeUntilRefresh = expireTime.diff(curTime) - 60000;
    if (timeUntilRefresh > 0) {
      setInterval(async () => {
        const newWebIdentityToken = await getWebIdentityTokenOrRedirect();
        credentials = await generateCredentials(
          roleArn,
          roleSessionName,
          newWebIdentityToken
        );
      }, timeUntilRefresh);
    }
  }
}

export async function initCredentials() {
  const webIdentityToken = await getWebIdentityTokenOrRedirect();
  const userAlias = getUserAlias(webIdentityToken);

  stsClient = new STSClient({
    region: APP_CONFIG.region,
  });

  if (userAlias !== undefined && APP_CONFIG.assumedRoleArn) {
    await refreshCredentials(
      APP_CONFIG.assumedRoleArn,
      userAlias,
      webIdentityToken
    );
  }

  if (userAlias === undefined) {
    return null;
  }
}

export function getLatestCredentials() {
  if (
    credentials !== undefined &&
    credentials.AccessKeyId !== undefined &&
    credentials.SecretAccessKey !== undefined &&
    credentials.SessionToken !== undefined
  ) {
    return {
      accessKeyId: credentials.AccessKeyId,
      secretAccessKey: credentials.SecretAccessKey,
      sessionToken: credentials.SessionToken,
    };
  }
  return undefined;
}
